<template>
<div>
    <v-container>

        <h1 class="text-center mb-10 page_title">料金表</h1>
        <div class="fee_table_outer mx-auto">
            <div class="fee_table">
               
                <v-simple-table v-if="fees">
                    <tr>
                        <th class="border-bottom"> <div class="f-10 bold f-gray scroll ml-3">スクロール <span class="arrow1">＞</span><span class="arrow2">＞</span><span class="arrow3">＞</span></div></th>
                        <th class="border-bottom"></th>
                        <th class="border-bottom text-right">日帰り</th>
                        <th class="border-bottom text-right fee_th">１泊</th>
                        <th class="border-bottom text-right fee_th">２泊</th>
                    </tr>

                    <tr v-for="(fee,i) in fees" :key="i">
                        <th rowspan="2" class="border-bottom" v-if="i!=fees.length-1&&fees[i].service==fees[i+1].service">{{fees[i].service}}</th>
                        <th class="border-bottom" v-if="(i==fees.length-1&&fees[i].service!=fees[i-1].service)||(i==0)&&fees[i].service!=fees[i+1].service||(i>0&&fees[i].service!=fees[i-1].service&&i!=fees.length-1&&fees[i].service!=fees[i+1].service)">{{fees[i].service}}</th>
                        <th :class="{'border-bottom':i==fees.length-1||fees[i].service!=fees[i+1].service}">{{fees[i].unit}}</th>
                        <td class="border-bottom text-right">{{fee.fee1>0?'￥'+(Number(fee.fee1)).toLocaleString():""}}</td>
                        <td class="border-bottom text-right">{{fee.fee2>0?'￥'+(Number(fee.fee2)).toLocaleString():""}}</td>
                        <td class="border-bottom text-right">{{fee.fee3>0?'￥'+(Number(fee.fee3)).toLocaleString():""}}</td>
                    </tr>
                </v-simple-table>
                <p class="text-right mt-2 f-09 pr-1">料金は全て税込</p>
            </div>
        </div>
    </v-container>

</div>
</template>

<script>
import axios from "axios"

export default {
    data() {
        return {
            fees: 0
        }
    },

    async mounted() {

        const response = await axios.post('/master/fees')
        this.fees = response.data.fees

    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/fees.scss";
</style>
